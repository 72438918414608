import { graphQlClient } from "lib/graphql-client.js";
/* eslint-disable @next/next/no-img-element */
import { CldImage } from "next-cloudinary";
import Layout from "../components/Layout";

import useWindowSize from "../lib/hooks/useWindowSize";
import Button from "components/ui/buttons/Button/Button";

import { fetchLayoutData, fetchLayoutDataServer } from "lib/api/strapi-layout";

import styles from "./404.module.scss";

const ErrorPage = ({ errorPage, layoutData }) => {
  const { width } = useWindowSize();

  return (
    <Layout
      seo={{
        ...errorPage?.search_engine_optimization,
        noindex: true,
        nofollow: true,
      }}
      layoutData={layoutData}
    >
      <main className={`${styles.background} variable-container-flush`}>
        {width >= 768 ? (
          <CldImage
            src={
              errorPage?.bgImage?.url ??
              "https://res.cloudinary.com/dail4fbms/image/upload/c_limit,w_1366/f_auto/q_auto/v1629963392/404_hitchhiker_77cc906590?_a=BAVAqQIB0"
            }
            alt="Background image"
            width="1366"
            height="660"
            className={`${styles.backgroundImage}`}
          />
        ) : (
          <CldImage
            src={
              errorPage?.bgImageSmall?.url ??
              "https://res.cloudinary.com/dail4fbms/image/upload/c_limit,w_1366/f_auto/q_auto/v1629963392/404_hitchhiker_77cc906590?_a=BAVAqQIB0"
            }
            alt="Background image"
            width="320"
            height="573"
            className={`${styles.backgroundImage}`}
            loading="lazy"
          />
        )}

        <div className={`${styles.textbox}`}>
          <p className={`${styles.error}`}>404 ERROR</p>
          <h1 className={`${styles.heading}`}>{errorPage?.heading ?? "404"}</h1>
          <h3 className={`${styles.description}`}>
            {errorPage?.description ?? "404"}
          </h3>
          <div className={`${styles.button}`}>
            <Button link="/">{errorPage?.buttonText ?? "Go Back"}</Button>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ErrorPage;

export async function getStaticProps() {
  const { ApolloClient, InMemoryCache, gql } = await import("@apollo/client");

  const strapiApolloClient = graphQlClient();

  const errorPageResponse = await strapiApolloClient.query({
    query: gql`
      query ErrorPageQuery {
        error404 {
          data {
            attributes {
              heading
              description
              buttonText
              bgImage {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              bgImageSmall {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              search_engine_optimization {
                metaTitle
                metaDescription
                metaMedia {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
                preventIndexing
              }
            }
          }
        }
      }
    `,
  });

  const errorPageData =
    await errorPageResponse?.data?.error404?.data?.attributes;
  const errorPage = {
    ...errorPageData,
    bgImage: { ...errorPageData?.bgImage?.data?.attributes },
    bgImageSmall: { ...errorPageData?.bgImageSmall?.data?.attributes },
    search_engine_optimization: {
      ...errorPageData?.search_engine_optimization,
      metaMedia: {
        ...errorPageData?.search_engine_optimization?.metaMedia?.data
          ?.attributes,
      },
    },
  };

  // const layoutData = await fetchLayoutData();
  const layoutData = await fetchLayoutDataServer();

  return {
    props: { errorPage, layoutData: layoutData },
  };
}
